const key = 'estoqueAuto__us3r';
export function getCurrentUser() {
  let json = localStorage.getItem(key);

  return json ? JSON.parse(json) : userEmpty();
}

export function setCurrentUser(obj) {
  let json = JSON.stringify(obj);
  return localStorage.setItem(key, json);
}

export function userEmpty() {
  return {
    Nome: '',
    CpfCnpj: '',
    RGIE: '',
    ApelidoRazaoSocial: '',

    CEP: '',
    Bairro: '',
    Logradouro: '',
    Numero: '',
    Complemento: '',
    Cidade: '',
    CidadeId: '',
    Estado: null,

    Telefone: '',
    TelefoneCom: '',
    TipoContato: '',
    TratarCom: '',
    Setor: '',

    Garantia: 90,
    Validade: 30,
    Logo: '',
    LogoFileName: '',

    Email: '',
    Senha: '',
    ConfirmacaoSenha: '',
    Plano: '',
    Termos: false

  }
}

