<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left mt-3">
        <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto Logo" />
      </a>
      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Cadastro grátis</h2>
        </div>
        <div class="card-body">
          <h4 class="mt-0 mb-4 font-weight-bold">
            4. Regras<br />
            <small class="text-muted">Informe as regras do seu negócio.</small>
          </h4>
          <form action="/auth/login" id="form" method="post">
            <div class="form-group mb-3">
              <label>Validade da avaliação (em dias)</label>
              <div class="input-group">
                <the-mask :mask="['###']" type="tel" v-model="userData.Validade" class="form-control-lg form-control" @focus.native="removeError('Validade')" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-calendar-check"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Validade'] }}</span>

            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Garantia de veículos (em dias)</label>
              </div>
              <div class="input-group">
                <the-mask :mask="['###']" type="tel" v-model="userData.Garantia" class="form-control-lg form-control" @focus.native="removeError('Garantia')" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-car"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Garantia'] }}</span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Imagem com sua logo (opcional)</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" type="file" accept="image/x-png,image/gif,image/jpeg" @change="encodeImageFileAsURL" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-image"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Logo'] }}</span>
              <div class="row">
                <div class="col-6 offset-3 mt-4">
                  <img style="width:100%" :src="userData.Logo" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" @click="anterior()" class="btn btn-default mt-2 float-left" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Voltar'}}
                </button>
                <button type="button" @click="proximo()" class="btn btn-primary mt-2 float-right" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Próximo'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import {getCurrentUser,setCurrentUser,userEmpty } from '@/services/external.helpers.js';

export default {
    data() {
        return {
            userData: userEmpty(),
            errors: {},
            loading: false
        };
    },
    created() {
      this.userData = getCurrentUser();
    },
    methods: {
      proximo: function () {
        if (this.validar()) {
          setCurrentUser(this.userData);
          this.$router.push('/cadastro/conta');  
        }else {
          window.scrollTo(0,0);
        }     
      },
      anterior: function () {
        setCurrentUser(this.userData);

          this.$router.push('/cadastro/contato');                    
      },
      encodeImageFileAsURL($event) {
        let element = $event.target;
        let that = this;
        var file = element.files[0];
        that.userData.LogoFileName = file.name;
        if (file.size > 1000000) {
          let obj = new Object();
          for (var i in this.errors) {
            obj[i] = this.errors[i];
          }
          obj['Logo'] = "Imagem muito grande. Escolha uma imagem menor ou igual a 2MB.";
          this.errors = obj;
        }
        else {
          var reader = new FileReader();
          reader.onloadend = function() {
            that.userData.Logo = reader.result;
          }
          reader.readAsDataURL(file);
        }
      },
      removeError(prop) {
        this.errors[prop] = '';
        delete this.errors[prop];

        this.$nextTick(() => {
          let obj = new Object();
          for (var i in this.errors) {
            obj[i] = this.errors[i];
          }
          this.errors = obj;
        });
      },
      validar() {
        this.errors = {};
        if (!this.userData.Validade || parseInt(this.userData.Validade) < 0) {
          this.errors['Validade'] = 'Validade inválida.';
        }
        if (!this.userData.Garantia || parseInt(this.userData.Garantia) < 0) {
          this.errors['Garantia'] = 'Garantia inválida.';
        }

        return Object.keys(this.errors).length === 0;
      },

    },
}

</script>
